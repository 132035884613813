import "./generic-carousel-styles.scss";
import { initializeAndRegisterCarouselController } from "./carousel-handles";

export const CAROUSEL_CLASS_NAME = "carousel";

for (const carouselRootElement of document.getElementsByClassName(
  CAROUSEL_CLASS_NAME
)) {
  if (carouselRootElement instanceof HTMLElement) {
    try {
      initializeAndRegisterCarouselController(carouselRootElement);
    } catch (error: unknown) {
      // eslint-disable-next-line no-console
      console.error(
        "Failed to initialize carousel element: ",
        error,
        carouselRootElement
      );
    }
  }
}
